import React                   from 'react';
import { Link }                from 'gatsby';
import styled                  from 'styled-components';
import { Container, Col, Row } from 'react-grid-system';

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.brandColor};
  text-decoration: underline;
`;

function CallToAction() {
  return (
    <Container fluid style={{ maxWidth: '600px' }}>
      <Row>
        <Col md={12}>
          <h4>Wir empfehlen eine Terminvereinbarung zu Ihrem persönlichen Beratungsgespräch</h4>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <StyledLink to='/kontakt'>Kontaktieren Sie uns per Email</StyledLink>
        </Col>
        <Col md={6}>
          <p>oder telefonisch unter: 0212 / 204654</p>
        </Col>
      </Row>
    </Container>
  )
}

export default CallToAction;

// TODO get data from config via graphql